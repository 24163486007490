.video-js {
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}