.circle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  mix-blend-mode: screen;
}

.white {
  background: #fff;
  left: 198px;
  top: 148px;
  width: 204px;
  height: 204px;
}

.red {
  background: #f00;
  animation: uOrbit 6s linear infinite;
}

.green {
  background: #0f0;
  animation: uOrbit 3s linear infinite;
}

.blue {
  background: #00f;
  animation: sOrbit 2s linear infinite;
}

.red,
.green,
.blue {
  filter: blur(4px);
}

@keyframes sOrbit {
  from {
    transform: rotate(360deg) translateX(-6px) rotate(-360deg);
  }
  to {
    transform: rotate(0deg) translateX(-6px) rotate(0deg);
  }
}

@keyframes uOrbit {
  from {
    transform: rotate(0deg) translateX(9px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(9px) rotate(-360deg);
  }
}
